import React from 'react';
import clubsData from './clubsInfo.js';

function KlubInformacije({ id }) {
  const targetClub = clubsData.filter((club) => club.id === Number(id));

  if (!targetClub.length) {
    // Handle the case where the club with the specified ID is not found
    return <div>Club not found</div>;
  }

  const treneri = targetClub[0].coach ? Object.values(targetClub[0].coach) : null;

  const divTreneri = treneri
  ? treneri.map((trener, index) => (
      <div key={index} style={{ fontFamily: 'Times New Roman, serif' }}>
        {trener}
      </div>
    ))
  : <div>/</div>;

  const logo = `/images/${id}.jpg`;

  return (
    <div className="mx-10">
       <div className="border p-4 rounded-lg max-w-md mx-auto my-8 flex flex-col items-center md:flex-row md:items-start md:justify-center md:text-left m-5" data-theme="fantasy">
        <div className="mb-4 md:mb-0 md:mr-8"
  style={{
    borderRadius: '10px',
    padding: '20px',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s ease',
    overflow: 'hidden',
  }}
>
  <div style={{ borderRadius: '10px', overflow: 'hidden' }}>
    <img
      src={logo}
      alt="Club Logo"
      className="w-40 h-auto md:w-40"
      style={{ borderRadius: '10px 10px 0 0', objectFit: 'cover' }}
    />
  </div>
</div>
        {/* Right Column (Club Information) */}
        <div>
          <div className="mb-2">
            {targetClub[0].name && <h2 className="text-2xl font-bold">{targetClub[0].name}</h2>}
          </div>
          <div className="mb-2">
            <strong>Voditelj/i:</strong>
            <div>{divTreneri}</div>
          </div>
          <div className="mb-2">
            <strong>Media:</strong>
            <p>www.instagram.com</p> {/* Replace with the actual  */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default KlubInformacije;