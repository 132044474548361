const clubsData = [
    {"id": 1, "name": 'MNK SV.Nikola Metković', "coach": {
        1:'Stipe Vladimir',
        2:'Mario Bokšić',
        3:'Roko Rastočić'
    }},

    {"id": 2, "name": 'MNK KLADA no Limit Metković', "coach": {
        1:'Ivan Popović"Popa"',
        2:'Mihovil Ćerlek',
        3:'Ante Majčica"Šajba"'
    }},

    {"id": 3, "name": 'MNK VID', "coach": {
        1:'Josip Volarević',
        2:'Mario Bukovac'
    }},

    {"id": 4, "name": 'MNK SV.ROKO PODRUJNICA', "coach": {
        1:'Jadranko Marević',
        2:'Drago Bezer',
        3:'Tino Ćelić'
    }},
    {"id": 5, "name": 'AMNK OPUZEN', "coach": {
        1:'Josip Kudin',
        2:'Josip Tošić'
    }},
    {"id": 6, "name": 'MNK ŠKRAPA MOMIĆI', "coach": {
        1:'Alen Mušan',
        2:'Ivan Plećaš "Dundo"',
        3:'Tonći Jerković'
    }},
    {"id": 7, "name": 'MNK CRNI PUT pekarstvo Metkovka', "coach": {
        1:'Marko Alimić',
        2:'Zvonimir Dragović',
        3:'Josip Šiljeg'
    }},
    {"id": 8, "name": 'MRŠAVI PSI KOLJBABIĆ GRADNJA', "coach": {
        1:'David Jelavić',
        2:'Ivan "Anton" Peko'
    }},
    {"id": 9, "name": 'MNK PRUD', "coach": {
        1:'Ivan Volarević "Dundo"',
        2:'Petar Vuletić'
    }},
    {"id": 10, "name": 'MNK NEUM', "coach": null},
    {"id": 11, "name": 'ISPOD STREHE Metković', "coach": {
        1:'Ante Bebić',
        2:'Duje Manenica',
        3:'Karlo Taslak'
    }},
    {"id": 12, "name": 'MNK DOLJANI', "coach": {
        1:'Ante Palameta',
        2:'Pavo Dujmović'
    }}
  ];
  
  export default clubsData;